import axios from '~/services/apiClient'
import { getURI } from '~/constants/NewsTypeURI'

export default {

    getBoardList(params) {
        console.log('getBoardList params', params)
        /* const _params = {
      newsType: params.newsType,
      pageNo: params.pageNo || 1,
      countByPage: params.countByPage || 5,
    } */

        // 시장조치종목
        if(params.menuType === 'MARKET_FLASH_MARKET_ACTION'){
            params.newsType = params.menuType
        }

        let { menuType } = params

        // newsType으로 들어오는 경우
        if (!menuType) {
            const { newsType } = params
            menuType = newsType
        }

        /**
         * 임시 예외 처리
         */
        if (!getURI(menuType)) {
            console.warn('MenuType 에러 확인필요')
            return
        }

        const listURI = getURI(menuType).split(',')[0]
        console.log('getBoardList listURI', listURI)

        return callApi(listURI, params)

        return axios
            .post('market/board', params, {
                /* headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/json; charset=UTF-8',
        }, */
                // headers: { 'Content-Type': 'application/json; charset=utf-8' },
            })
            .then((response) => response.data)
    },

    getBoardContent(params) {
        const { newsType, url, id } = params

        let detailURI, _params

        if (url) {
            detailURI = url
            _params = { id }
        } else {
            /**
             * 임시 예외 처리
             */
            if (!getURI(newsType)) {
                console.warn('NewsType 에러 확인필요')
                return { data: null }
            }

            detailURI = getURI(newsType)

            if (!detailURI.includes('flash/html')) {
                detailURI = detailURI.split(',')[1]
            }

            _params = params
        }

        return callApi(detailURI, _params)
    },

    /*
     * Common
     */

    sessionCheck(params) {
        return callApi('hello', params)
    },

    // 23.03.16 SUYE 홈페이지 조회수 
    getHitsMain(params) {
        return callApi('common/hitsmain', params); 
    },

    getIndex(params) {
        return callApi('common/index', params)
    },
    getTodaysWorld(params) {
        return callApi('common/todayworld', params)
    },
    getTopTheme(params) {
        return callApi('common/toptheme', params)
    },

    getSuccess(params) {
        return callApi('common/issuccess', params)
    },

    getTickerDetail(params) {
        return callApi('flash/html', params)
    },

    // NewsFlash
    getNewsFlash(params) {
        return callApi('flash/list', params)
    },

    /*
     * Initial
     */
    // 초기 corp_name과 code 받아오는 부분
    getInitial(params) {
        return callApi('common/initial', params)
    },
    /*
     *뉴스타입별 증권사 리스트
     * 증권사별 추천 종목 =  STOCK_NEW_RECOMMEND
     */
    getStockCompanyList(params) {
        return callApi('stock/company', params)
    },

    /**
     * Member
     */
    // 회원가입
    signUp(params) {
        return callApi('member/signup', params)
    },
    // id 중복 체크
    checkIdDup(params) {
        return callApi('member/check', params)
    },
    // 인증번호 요청
    requestCertiCode(params) {
        return callApi('member/phone/request', params)
    },
    // 인증번호 확인
    confirmCertiCode(params) {
        return callApi('member/phone/confirm', params)
    },
    // SNS 멤버 삭제 요청
    requestMemberDelete(params) {
        return callApi('member/delete', params)
    },
    // 로그인
    login(params) {
        return callApi('member/login', params)
    },

    // 20231116
    // SNS로그인
    SNSlogin(params) {
        return callApi('member/SNSlogin', params)
    },
    // SNS회원가입
    SNSsignUp(params) {
        return callApi('member/SNSsignup', params)
    },
    
    logout(params) {
        return callApi('member/logout', params)
    },
    // 220729 meej 자동 로그인
    loginToLink(parmas){
        return callApi('member/loginLink', parmas)
    },
    // 회원 정보
    getMemberInfo(params) {
        return callApi('member/info', params)
    },
    // 결제
    requestPayment(params) {
        return callApi('member/pay/request', params)
    },
    // 비밀번호 변경
    updatePassword(params) {
        return callApi('member/passwd', params)
    },
    // 회원정보 수정
    updateNickname(params) {
        return callApi('member/edit', params)
    },
    // 회원 탈퇴
    deleteAccount(params) {
        return callApi('member/withdraw', params)
    },
    // 결제 확인하기
    confirmPayment(params) {
        return callApi('member/pay/confirm', params)
    },
    // 주식신문 받는방식 수정
    updateKakaoType(params) {
        return callApi('member/kakaoType', params)
    },
    /* 20210527 shb 추가 */
    // 아이디,비번 찾기
    findIdPwd(params) {
        return callApi('/member/find', params)
    },
    // 비번 변경
    changePwd(params) {
        return callApi('/member/find/passwd', params)
    },

    /*
     * 1:1문의게시판,무통장 입금확인
     */
    // 등록 및 수정
    registAmodifyBoard(params) {
        return callApi('board/write', params)
    },
    // 문의사항 조회
    boardList(params) {
        return callApi('board/list', params)
    },
    /*
     * Company
     */
    // Company/Analysis 탭
    getCorpInfo(params) {
        return callApi('stock/info', params)
    },
    // 2023 컨센서스
    getCorpCONSENSUS(params){
        return callApi('stock/consensus', params)
    },
    // 2023 컨센서스 차트
    getCorpCONSENSUS_CHART(params){
        return callApi('stock/consensusChart', params)
    },
    // Company/Analysis 탭
    getCorpHistory(params) {
        return callApi('stock/history', params)
    },
    // Company/Analysis 탭
    getCorpTheme(params) {
        return callApi('stock/theme', params)
    },
    // Company/Analysis 탭 : 기업명 변경 종목 
    getStockNameChanged(params) {
        return callApi('stock/changed', params)
    },
    // Company/Price Chart
    getCorpPrice(params) {
        return callApi('stock/price/list', params)
    },
    // Company/Features
    getFeatues(params) {
        return callApi('stock/news/list', params)
    },
    // Company/TodayIssues
    getTodayIssues(params) {
        return callApi('stock/news/list', params)
    },
    // Company/OvertimeUnusual
    getUnusual(params) {
        return callApi('stock/news/list', params)
    },
    // Company/JumpSlumpReason
    getJumpSlumpReason(params) {
        return callApi('stock/news/board', params)
    },
    // Company/shortSellingRanking
    getShortSellingRanking(params) {
        return callApi('flash/list', params)
    },
    getRceptNoNm(params){
        return callApi('stock/rceptnonm', params)
    },

    /**
     * Issue & Theme Schedule
     */
    getMonthSchedule(params) {
        return callApi('event/month', params)
    },
    getMonthSampleSchedule(params) {
        return callApi('event/monthSample', params)
    },
    getYearSchedule(params) {
        return callApi('event/year', params)
    },
    // TODO : 추후 확인 _suki
    getPeriodSchedule(params) {
        return callApi('event/period', params)
    },

    /**
     * Research
     */
    // 데일리신규추천 DailyRecommend
    getDailyRecommend(params) {
        return callApi('research/recommend/daily', params)
    },
    // 증권사별 추천종목 RecommendByStockFirm
    getRecommendByStockFirm(params) {
        return callApi('research/recommend/company', params)
    },
    // 추천 제외종목 ExcludeRecommend
    getExcludeRecommend(params) {
        return callApi('research/recommend/excluded', params)
    },
    // 포트폴리오 수익률 순위
    getPortfolioEarningRateRanking() {
        return callApi('research/profit/portfolio', {})
    },
    // 종목별 수익률 순위
    getStockEarningRateRanking() {
        return callApi('research/profit/stock', {})
    },
    // 증권사 강력추천, 투자의견 조회
    getStockFirmOpinionAndStrongBuying(params) {
        return callApi('stock/news/list', params)
    },
    // 주요 기업 실적 
    getMainEarning(params) {
        return callApi('research/mainEarning', params)
    },

    /**
     * Theme
     */
    getThemeList(params) {
        return axios
            .post('theme/list', params, {})
            .then((response) => response.data)
    },
    // 테마브리핑
    getThemePdf(params) {
        return callApi('market/pdf', params)
    },
    getThemeAll() {
        return axios.post('theme/all', {}).then((response) => response.data)
    },
    getThemeDetail(params) {
        return callApi('theme/detail', params)
    },

    // ticker
    getTickerList(nextkey) {
        const params = {
            count: 150,
        }
        if (nextkey) {
            params.nextKey = nextkey
        }
        console.log(params)
        return callApi('common/ticker', params)
    },

    // 220531 meej 시황속보 리스트 수정
    getTickerListSearch(params) {
        return callApi('common/tickerList', params)
    },

    getMainTickerList2() {
        const params = {
            count: 5,
        }
        return callApi('common/ticker2', params)
    },

    getMainTickerList() {
        const params = {
            count: 5,
        }
        return callApi('common/ticker', params)
    },

    // 주식신문
    getNewsPaper(params) {
        return callApi('common/paper', params)
    },

    // 주식신문 - 상세보기
    getNewsPaperDetail(params) {
        return callApi('market/newspaperdetail', params)
    },

    // getHtmlFile
    getHtmlFile(params) {
        return axios
            .get(`common${params}`, {})
            .then((response) => response.data)
    },

    // 공지사항
    getNoticeBoardList(params) {
        return callApi('board/list', params)
    },
    // 공지사항 - 디테일
    getNoticeBoardDetail(params) {
        return callApi('board/detail', params)
    },

    // 광고게시판
    getAdBoardList(params) {
        return callApi('board/ad/list', params)
    },
    // 광고게시판 - 디테일
    getAdBoardDetail(params) {
        return callApi('board/ad/detail', params)
    },

     // 220719 meej 테마 index 리스트 
     getThemeIndexList(params) {
        return axios
            .post('theme/indexList', params, {})
            .then((response) => response.data)
    },
    /* 220502 meej 테마 상세 chart 추가 */
    getChart(params) {
        console.log('getChart' + params)
        return callChartApi(params)
    },
    /* 220617 meej 테마Index 종목 리스트 */
    getIndexStock(params) {
        console.log('getIndexStock' + params)
        return callIndexStockApi(params)
    },
    /* 220324 meej 최근 이슈 발생 현황 */
    getRecentIssue(params) {
        return callApi('stock/recentIssue', params)
    },
    /* 220324 meej 최근 이슈 발생 현황 - 종목별 세부 내용 조회 */
    getRecentIssueDetail(params) {
        return callApi('stock/recentIssueDetail', params)
    },
    /* 220530 meej 종목히스토리 검색 - 구분 타입 조회 */
    newsType(params) {
        return callApi('common/newstype', params);
    },
    /* 220530 meej 종목히스토리 검색 - 리스트 조회 */
    getStockHistory(params) {
        return callApi('stock/stockHistory', params);
    },
}

/* 220510 meej 테마 상세 chart 추가 */
const callChartApi = (param) => {
    console.log('callChartApi uri')
    console.log('callChart' + param)
    return (
        axios
            .get('https://infostockindex.co.kr/index_group/index'
                , {
                    params: {
                        theme_code: param
                    }
                }, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                    },
                })
            .then((response) => response)
            .catch((err) => {
                console.log('errorAPI=', err)
            })
    )
}
/* 220617 meej 테마 Index 종목 리스트 추가 */
const callIndexStockApi = (param) => {
    console.log('callChartApi uri')
    console.log('callChart' + param)
    return (
        axios
            .get('https://infostockindex.co.kr/index_group/index-stock'
                , {
                    params: {
                        theme_code: param
                    }
                }, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                    },
                })
            .then((response) => response)
            .catch((err) => {
                console.log('errorAPI=', err)
            })
    )
}

const callApi = (uri, params) => {
    console.log('callApi uri', uri)
    return (
        axios
            .post(uri, params, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            },{timeout:600000})
            .then((response) => response.data)
            .catch((err) => {
                console.log('errorAPI=', err)
            })
    )
}

// or simulate network delay
function sleeper() {
    const ms = Math.random() * 10000
    console.log('sleeper ms', ms)
    return function(x) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(x)
            }, ms),
        )
        // return apiMode == 'json'
        //   ? new Promise((resolve) =>
        //       setTimeout(() => {
        //         console.log('sleeper ms', ms);
        //         resolve(x);
        //       }, ms)
        //     )
        //   : new Promise((resolve) => resolve(x));
    }
}
