//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import api from '~/api'

export default {
  name: 'FindIdSns',
  data() {
      return {
          phoneNo: '',
          certifiNo: '',
          findid: '',
          id: '',
          certifiInput: false,
          NotMember:false,
          idVisible: false,
          seconds: 0,
          certifiError: null,
          isCertifiDone: false, // 인증 완료 구분값
          btnStyleCon: 'disabled', // 인증번호 발송 버튼 스타일 
          btnStyleBlock: 'cursor: not-allowed',
      }
  },
  computed: {
      ...mapState('modal', ['findIdShow']),
  },
  watch: {
      seconds() {
          this.minutes(this.seconds)
      },
      findIdShow() {
          if (!this.findIdShow) {
              this.resetData()
          }
      },
      phoneNo(){
          if(this.phoneNo){
              if(this.phoneNo.length === 10 || this.phoneNo.length === 11){
                  this.btnStyleCon = ''
                  this.btnStyleBlock = ''
              } else {
                  this.btnStyleCon = 'disabled'
                  this.btnStyleBlock = 'cursor: not-allowed'
              }
          }
      },
      findid(val){
          if(val){
              this.btnStyleCon = 'disabled'
              this.btnStyleBlock = 'cursor: not-allowed'
          } 
      },
  },
  created() {
      this.resetData()
  },
  methods: {
      ...mapActions('modal', ['HIDE_MODAL']),
      ...mapMutations('member', ['SET_MEMBER_PHONE_NUM']),
      minutes(time) {
          const minutes = Math.floor(time / 60)
          let seconds = time - minutes * 60

          if (seconds < 10) {
              seconds = '0' + seconds
          }

          const converted = minutes + ':' + seconds
          return converted
      },
      tickingTimer() {
          if (this.seconds > 0) {
              this.seconds--

              this.timer = setTimeout(() => {
                  this.tickingTimer()
              }, 1000)
          } else {
              clearTimeout(this.timer)
              // if(!this.isCertifiDone) { // 인증 완료 후에는 타임 경고 비현출 
              //     this.seconds = -1
              // }
          }
      },
      async sendNo() {
          const res = await api.requestCertiCode({ phone: this.phoneNo })
          if (res && res.success) {
              // 요청버튼 눌렀을 때, 시간 할당 후, 타이머 시작
              this.seconds = 180
              this.tickingTimer()
              this.certifiInput = true
          } else {
              alert('휴대폰 인증시 오류가 발생하였습니다.')
              this.certifiInput = false
          }
          // csertifithis.phoneNo
      },
      async sendCertifi() {
          const res = await api.findIdPwd({
              code: this.certifiNo,
              phone: this.phoneNo,
          })
          if (res && res.success) {
              this.seconds = 0
              const data = res.data
              if (data.id.length !== 0) {
                  this.NotMember = false
                  this.id = data.id
                  this.findid = `ID : ${data.id}`
                  this.idVisible = true
                  // 아이디 찾기 완료시
                  this.isCertifiDone = true             
              } else {
                  this.NotMember = true
                  this.findid = '해당하는 번호의 아이디가 없습니다.'
                  this.idVisible = true
              }
          } else if (res && res.status === '404') {
              this.certifiError = '인증 번호를 확인해주세요.'
          } else {
              // 해당 번호의 아이디 없을 시 
              this.NotMember = true
              clearTimeout(this.timer)
              this.isCertifiDone = true
              this.findid = '해당하는 번호의 아이디가 없습니다.'
              this.idVisible = true
          }
      },

      resetData() {
          this.phoneNo = ''
          this.certifiNo = ''
          this.findid = ''
          this.certifiInput = false
          this.idVisible = false
          this.certifiError = null
          this.seconds = 0
          this.isCertifiDone = false
          this.btnStyleCon = 'disabled'
      },
      onClickClose() {
          this.resetData()
          this.HIDE_MODAL()
      },
      onClickClose2() {
          this.SET_MEMBER_PHONE_NUM(this.phoneNo);
        //   this.$emit('authentication-completed');
          this.resetData()
          this.HIDE_MODAL()
      },
      onClickDelete(){
        this.delete()
      },
      async delete() {
        console.log(" 멤버 아이디 조회 : ", this.id)
        const res = await api.requestMemberDelete({ id: this.id }) // 'ID : ' 부분을 제거하고 id만 추출
        if (res && res.success) {
            // 회원 삭제 성공 시 처리 로직
            this.SET_MEMBER_PHONE_NUM(this.phoneNo);
            this.resetData()
            // alert('회원이 성공적으로 삭제되었습니다.')
            this.HIDE_MODAL()
        } else {
            // 실패 시 처리 로직
            alert('회원 삭제에 실패하였습니다.')
        }
    },
  }
}
